<template>
  <div>
    <div class="order_wrap">
      <div class="order_con">
        <div class="order_info">
          <h6>订单信息</h6>
          <div class="order_list">
            <div class="order_list_item">
              <!--              <label>产品规格:</label>-->
              <div class="order_detail_con">
                <!--                商标-->
                <!--                <div class="order_detail">-->
                <!--                  <dl>-->
                <!--                    <dt>产品名称</dt>-->
                <!--                    <dd><span>{{tableData[0].name}}</span></dd>-->
                <!--                  </dl>-->
                <!--                  <dl>-->
                <!--                    <dt>{{trademark?'数据库':'语言方向'}}</dt>-->
                <!--                    <dd><span>{{tableData[0].database}}</span></dd>-->
                <!--                  </dl>-->
                <!--                  <dl >-->
                <!--                    <dt>周期</dt>-->
                <!--                    <dd><span>{{tableData[0].cycle}}</span></dd>-->
                <!--                  </dl>-->
                <!--                  <dl>-->
                <!--                    <dt>总数(日均)</dt>-->
                <!--                    <dd><span>{{tableData[0].total}}</span></dd>-->
                <!--                  </dl>-->
                <!--                  <dl>-->
                <!--                    <dt>每日限制</dt>-->
                <!--                    <dd><span>{{tableData[0].limit}}</span></dd>-->
                <!--                  </dl>-->
                <!--                </div>-->

                <el-table
                  cell-class-name="cellName"
                  header-row-class-name="headerRow"
                  ref="tableref"
                  :data="tableData"
                  style="width: 100%">
                  <el-table-column
                    prop="name"
                    align="center"
                    label="产品名称"
                    width="120">
                  </el-table-column>
                  <el-table-column
                    prop="database"
                    align="center"
                    :label="trademark?'数据库':'语言方向'"
                    width="180">
                  </el-table-column>
                  <el-table-column
                    align="center"
                    width="120"
                    prop="cycle"
                    label="周期">
                  </el-table-column>
                  <el-table-column
                    align="center"
                    width="120"
                    prop="total"
                    label="总数">
                  </el-table-column>
                  <el-table-column
                    width="120"
                    align="center"
                    prop="limit"
                    label="每日限制">
                  </el-table-column>
                </el-table>
              </div>

            </div>

            <div style="display: flex;flex-direction: column" class="order_list_item">
              <label>支付方式:</label>
              <div>
                <p v-for="(item,index) in payMethod" :key="index" :class="{'active':item.chooseBool}"
                   @click="payMethodEvent(item)">
                  <i></i>
                  <b><img :src="item.url" alt=""></b>
                  <span>{{item.name}}</span>
                </p>
              </div>

            </div>
          </div>
        </div>


        <div class="paymentAmount" style="margin-top: 60px;width: 1200px;display: flex" >
          <p style="padding-left: 15px" class="amount">应付金额: <span style="margin-left: 15px">{{price}}</span>&nbsp;元</p>
          <!--          <img v-if="$route.query.discountFlag == 1"  style="height: 18px" src="/images/index/active.png" alt="">-->
          <span v-if="$route.query.discountFlag == 1"   class="zhe">5折</span>
        </div>

        <div class="order_btn">
          <el-button :class="['el-order-btn',{'disabled':disabledBool}]" @click="payEvent">立即支付</el-button>
          <h6 class="order_hint">提示：确认支付前，请先<span @click="infoEvent">完善账户信息</span></h6>
        </div>


      </div>
      <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false" custom-class="account_dialog"
                 :before-close="closeEvent">
        <info @dialog="getDialog" :dialogVisible='dialogVisible' :server-type="0" :dialog-close="dialogClose"
              @dialogCloseBool="dialogCloseBool"></info>
      </el-dialog>
    </div>
    <xfooter></xfooter>
  </div>
</template>
<script>
  import { customizedOrder,payZfb } from "@/request/trademarkPayment";
  import zfb from '@/assets/images/common/zfb.jpg'
  import gong from '@/assets/images/common/gong.jpg'
  import wx from '@/assets/images/common/wxs.jpg'
  import xfooter from '@/components/common/xfooter'
  import info from "@/components/account/info"
  export default {
    name: "orderInfo",
    data () {
      return {
        payType: 'WX',
        period: ['', '一周', '一个月', '三个月', '半年', '一年', '两年', '三年'],
        //获取总数字
        numberPeriod: '',
        //商标
        trademark: true,
        //标识符--是否是对公支付
        corporateFlag: false,
        tableData: [{
          name: '专利翻译',
          database: '--',
          cycle: '',
          total: '--',
          limit:'--'
        }],
        payMethod: [
          {
            name: "微信支付",
            url: wx,
            chooseBool: true,
            type: "WX"
          },
          {
            name: "支付宝",
            url: zfb,
            chooseBool: false,
            type: "ZFB"
          },
          {
            name: "对公支付",
            url: gong,
            chooseBool: false,
            type: "gong"
          },
        ],
        detailData: {},
        disabledBool: false,
        dialogVisible: false,
        dialogClose: false,
        //订单编号
        orderNum:'',
        //数据库
        allDatabase:'',
        //价格
        price:'',
      }
    },
    mounted () {
      // this.init();
      // this.orderNum = localStorage.getItem('orderNum')
      // this.tableData[0].database = localStorage.getItem('allDatabase')
      // this.tableData[0].cycle = localStorage.getItem('cycle')
      // this.price = localStorage.getItem('price')

      // this.orderNum = localStorage.getItem('orderNum')
      this.trademark = this.$route.query.trademark == 'true' || this.$route.query.trademark == true ? true :false
      if(this.trademark){
        this.tableData[0].name = '商标查询'
        this.tableData[0].database = localStorage.getItem('allDatabase')
        this.tableData[0].cycle = this.period[this.$route.query.servicePeriod]
        this.price = localStorage.getItem('price')
      }else {
        this.tableData[0].name = '专利翻译'
        this.tableData[0].database = localStorage.getItem('language')
        this.tableData[0].cycle = this.period[this.$route.query.servicePeriod]
        this.price = localStorage.getItem('price')
      }
      // this.$refs.tableref.doLayout();
      if(this.$route.query.numberPeriod) this.tableData[0].total = this.$route.query.numberPeriod
      this.getContactLanAjax();
    },
    components: { info, xfooter },
    methods: {
      payLater(data) {
        if (data.payUrl.includes("paySuccess")) {
          window.location.href = data.payUrl;
        } else {
          const tradePage = data;
          const div = document.createElement('div'); // 创建div
          div.innerHTML = tradePage.payUrl; // 将返回的form 放入div
          document.body.appendChild(div);
          document.forms[0].submit();
          document.body.removeChild(div);
        }
      },

      //支付宝支付
      payWay3(orderId) {
        // if(window.location.origin=='http://www.youxuanzifu.com'||window.location.origin=='http://www.premiword.com.cn'){

        payZfb({
          channelId: "ALIPAY_PC",
          orderId: orderId,
          paymentType: 'PAY_ALL',
          basePath: window.location.origin
        })
          .then(({msg, code, data}) => {
            if (code) return;
            data = JSON.parse(data);
            this.payLater(data);
          })
          .catch((error) => {
            //   this.$message("系统未知错误,请反馈给管理员");
            console.log(error);
          })
      },
      // 选择
      pays(items){
        // let data = this.payMethod.filter(item => {
        //   return item.name == '对公支付'
        // })
        // if(data[0].chooseBool){
        //   this.$router.push({
        //     path: '/payment'
        //   })
        //   return ;
        // }
      },
      //   初始化
      async init () {
        let data = await this.$axios.get(`/product/productservicemanage/${this.$route.query.id}`);
        if (data.data.code == 0) {
          this.detailData = data.data.data;
        }
      },
      payMethodEvent (data) {
        let datas = []
        if (data.chooseBool) {
          return;
        }
        this.payMethod.forEach((item, index) => {
          item.chooseBool = false;
        })
        data.chooseBool = !data.chooseBool;
        this.payType = data.type
        datas = this.payMethod.filter(item => {
          return item.name == '对公支付'
        })
        if(datas[0].chooseBool == true){
          this.corporateFlag = true
        }else {
          this.corporateFlag = false
        }
      },
      payEvent () {


        this.saveOrderAjax();

      },
      // 生成订单
      async saveOrderAjax () {
        if(localStorage.getItem('orderId')){
          //对公支付
          if(this.corporateFlag){
            this.$router.push({
              path: '/payment',
              query:{
                orderId: this.$route.query.orderNum,
                name: this.tableData[0].name,
                price: this.price
              }
            })
            return ;
          }

          if(this.payType == 'ZFB'){
            this.payWay3(localStorage.getItem('orderId'))
            return;
          }

          this.$router.push({
            path: "/pay/pay",
            query: {
              orderId:  localStorage.getItem('orderId'),
              price:    this.price
            }
          })
        }else {
          let obj = {
            "keyword": this.trademark ? localStorage.getItem('keyword') : 'ALL' ,
            "servicePeriod": this.$route.query.servicePeriod ,
            "price": this.price,
            "source": 'web',
            "orderType": this.trademark ? 'tm_query' : 'pt_translate',
            "currency": undefined
          }
          customizedOrder(obj).then((res) => {
            if(res.code == 0){
              //对公支付
              if(this.corporateFlag){
                this.$router.push({
                  path: '/payment',
                  query:{
                    orderId:res.data.orderNum,
                    name: this.tableData[0].name,
                    price: this.price
                  }
                })
                return ;
              }

              if(this.payType == 'ZFB'){
                this.payWay3(res.data.id)
                return;
              }

              this.$router.push({
                path: "/pay/pay",
                query: {
                  orderId:  res.data.id,
                  price:    this.price
                }
              })
            }else {
              this.$message({
                message: '购买失败',
                type: 'warning'
              });
            }
          })
        }

      },
      infoEvent () {
        this.dialogVisible = true;
      },
      getDialog (param) {
        this.dialogVisible = param;
        this.getContactLanAjax();
      },
      //  获取联系人信息
      async getContactLanAjax () {
        let data = await this.$axios.get("/admin/contact/getContact");
        if (data.data.msg == "success") {
          if (data.data.data != null) {
            this.disabledBool = false;
          } else {
            this.disabledBool = true;
          }
        }
      },
      dialogCloseBool (data) {
        this.dialogClose = data;
      },
      closeEvent (done) {
        this.dialogClose = true;
        done();
      },
    }
  }
</script>
<style lang="less" scoped>
  .order_wrap {
    /*min-height: calc(100vh - 85px);*/
    padding: 74px 0px 0px;
    box-sizing: border-box;
    background: #f2f2f2;
    .order_con {
      width: 100%;
      min-height: calc(100vh - 183px);
      background: #fff;
      padding: 40px 0 80px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      box-shadow: 0px 3px 30px 0px rgba(152, 160, 164, 0.18);
      .order_info {
        width: 1200px;
        margin: 0 auto;
        h6 {
          color: #595a5e;
          font-size: 16px;
          position: relative;
          padding-left: 15px;
          margin-bottom: 30px;
          &::before {
            content: "";
            width: 4px;
            height: 22px;
            background-color: @blue;
            position: absolute;
            left: 0;
          }
        }
        .order_list {
          padding-left: 15px;
          .order_list_item {
            line-height: 55px;
            color: #595a5e;
            font-size: 14px;
            label {
              margin-right: 40px;
            }
            .red {
              color: #eb5657;
            }
            p {
              display: inline-block;
              height: 33px;
              line-height: 33px;
              padding: 0 15px;
              border: solid 1px #cccccc;
              position: relative;
              cursor: pointer;
              margin-right: 20px;
              b {
                display: inline-block;
                width: 20px;
                vertical-align: -6px;
                img {
                  display: block;
                  width: 100%;
                }
              }
              span {
                color: #333;
                font-size: 14px;
                margin-left: 6px;
              }
              &.active {
                border: solid 1px @blue;
                i {
                  width: 16px;
                  height: 16px;
                  background: url("@{imgUrl}common/choose.png") no-repeat center;
                  background-size: 100%;
                  position: absolute;
                  top: -5px;
                  right: -4px;
                }
              }
            }
            .order_detail_con {
              /deep/.el-table--border::after, /deep/.el-table--group::after, /deep/.el-table::before{
                background-color: transparent;
              }
              /deep/.headerRow{
                line-height: 0px;
              }
              /deep/.cellName{
                border-bottom: none;
              }
              display: inline-block;
              margin-bottom: 25px;
              .order_detail {
                display: flex;
                width: 420px;
                border-radius: 3px;
                /*border: solid 1px #ecf0f4;*/
                dl {
                  display: inline-block;
                  text-align: center;
                  flex: 1;
                  dt {
                    height: 37px;
                    /*background-color: #f8f9fb;*/
                    border-bottom: 1px solid #eff2f5;
                    line-height: 37px;
                    color: #999999;
                  }
                  dd {
                    line-height: normal;
                    padding: 10px 0;
                    span {
                      color: @blue;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .order_hint {
        width: 1200px;
        margin: 40px auto 24px;
        color: #999999;
        font-size: 14px;
        padding-left: 15px;
        box-sizing: border-box;
        span {
          color: @blue;
          cursor: pointer;
        }
      }
      .paymentAmount{
        margin: 50px auto 10px ;
        .amount{
          font-size: 18px;
          color: #595a5e;
          font-weight: bold;
          span{
            font-weight: 600;
            font-size: 34px;
            color: rgb( 255, 106, 55 );
          }
        }
        .zhe{
          margin-right: 3px;
          display: table-cell;
          width: 40px;
          height: 20px;
          background: #ff6a37;
          color: white;
          border-radius: 5px;
          font-size: 14px;
          vertical-align:middle;
          text-align:center;
          margin-left: 8px;
        }
        display: flex;
      }
      .order_btn {
        width: 1200px;
        margin: 0 auto;
        position: relative;
        display: flex;
        height: 40px;
        align-items: center;
        .el-order-btn {
          margin-left: 15px;
          width: 100px;
          height: 34px;
          background: @blue!important;
          font-size: 14px;
          line-height: 34px;
          border-radius: 3px;
          color: #fff;
          padding: 0;
          border: none;
          &:hover {
            box-shadow: @boxShadow;
          }
          &.disabled {
            background-color: #d9d9dc;
            cursor: default;
            &:hover {
              box-shadow: none;
            }
          }
        }
      }
    }
  }
</style>
<style lang="less">
  .wxCode_dialog {
    .el-dialog__header {
      display: none;
    }
    #qrcode {
      width: 220px;
      height: 220px;
      margin: 0 auto;
    }
  }
</style>
<style lang="less">
  .account_dialog {
    width: 600px;
    height: 740px;
    background-color: #ffffff;
    box-shadow: 0px 6px 48px 0px rgba(128, 128, 128, 0.16);
    border-radius: 5px;
    .el-dialog__header,
    .el-dialog__body {
      padding: 0;
    }
    .info_wrap {
      .info_con {
        padding: 45px 80px 80px;
      }
    }
  }
</style>

